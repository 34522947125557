import Phaser from 'phaser';

export default class Boot extends Phaser.Scene {
	constructor() {
		super('Boot');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'Boot' });
	}

	preload() {
		//  The Boot Scene is typically used to load in any assets you require for your Preloader, such as a game logo or background.
		//  The smaller the file size of the assets, the better, as the Boot Scene itself has no preloader.
		//this.load.image('game-logo-512', './dogmutant_game_logo.png');
		this.load.image('loading-bg', './dogmutant_loading_bg.jpg');
		this.load.rexWebFont({
			google: {
				families: ['Barlow Condensed'],
			},
			// testString: undefined,
			// testInterval: 20,
		});
	}

	create() {
		this.scene.start('Preloader');
	}
}
