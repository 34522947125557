import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import ItemStatRow from '../components/ItemStatRow';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

export default class LeaderboardPopup extends Phaser.Scene {
	constructor() {
		super('LeaderboardPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'LeaderboardPopup' });
	}

	async init(data) {
		console.log(data);

		//this.compareData = data;
	}

	preload() {}

	async create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		r1.on('pointerup', function() {
			this.scene.scene.stop('LeaderboardPopup');
		});

		var popupBG = this.add.nineslice(360, 640, 'main', 'popup_bg', 650, 1000, 40, 40, 40, 50);
		popupBG.setInteractive();

		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Leaderboard', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var childPanel = this.rexUI.add.sizer({
			x: 0,
			y: 0,
			orientation: 'y',
			space: { item: 10, top: 10, bottom: 10 },
		});

		const leaderboardData = await getLeaderboard();

		for (var i = 0; i < leaderboardData.data.length; i++) {
			childPanel.add(this.createItemRenderer(i + 1, leaderboardData.data[i]));
		}

		//var abc = this.createItemRenderer();

		var config = {
			x: popupBG.x,
			y: popupBG.y - 50,
			width: popupBG.width,
			height: popupBG.height - 220,
			scrollMode: 0,
			slider: false,
			panel: {
				child: childPanel,
				mask: {
					padding: 0,
				},
			},
		};

		var panel = this.rexUI.add
			.scrollablePanel(config)
			.layout()
			.setOrigin(0.5, 0.5);

		var myBg = this.add.nineslice(popupBG.x, popupBG.y + popupBG.height / 2 - 80, 'main', 'rank_row_player_bg', 600, 120, 13, 13, 9, 18);
		var myName = this.add.text(myBg.x - 150, myBg.y - 38, leaderboardData.myName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'left' }).setOrigin(0, 0);
		var myElo = this.add.text(myBg.x - 150, myBg.y - 5, 'Elo: ' + leaderboardData.myElo.toString(), { fontFamily: 'Barlow Condensed', fontSize: '35px', fill: 'yellow', align: 'left' }).setOrigin(0, 0);
		var varMyIndex = this.add.text(myBg.x - 250, myBg.y, leaderboardData.myPosition, { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);
		//
	}

	createItemRenderer(index, data, frame = 'rank_row_bg') {
		var container = this.add.container();

		var itemBg = this.add.nineslice(0, 0, 'main', 'rank_row_bg', 600, 120, 13, 13, 9, 18);
		container.add(itemBg);
		container.setSize(itemBg.width, itemBg.height);
		var indexTxt = this.add.text(-250, 0, index, { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);

		if (index < 4) {
			var medal = this.add.image(indexTxt.x, indexTxt.y, 'main', 'medal_' + index);
			container.add(medal);
		}

		var name = this.add.text(-150, -38, data.name, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'left' }).setOrigin(0, 0);
		var elo = this.add.text(-150, -5, 'Elo: ' + data.elo.toString(), { fontFamily: 'Barlow Condensed', fontSize: '35px', fill: 'yellow', align: 'left' }).setOrigin(0, 0);

		container.add(name);
		container.add(elo);
		//
		container.add(indexTxt);

		return container;
	}
}
