import Phaser from 'phaser';
import { Skin } from '@esotericsoftware/spine-phaser';
const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: 'bold 50px', fill: 'white', align: 'center' };
import ChooseCharacterButton from '../components/ChooseCharacterButton';
import IconButton from '../components/IconButton';
export default class ChooseCharacterScene extends Phaser.Scene {
	constructor() {
		super('ChooseCharacterScene');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'ChooseCharacterScene' });
	}

	async init(data) {
		console.log(data);

		this.chars = data.data;
	}

	preload() {}

	create() {
		this.gamebg = this.add.image(0, 0, 'choose_character_bg');
		this.gamebg.x = this.gamebg.width / 2;
		this.gamebg.y = this.gamebg.height / 2;
		this.gamebg.setInteractive();

		this.topline = this.add.image(this.gamebg.x, 620, 'main2', 'character_stat_line');
		this.createStat([0, 0, 0]);

		this.description = this.add.text(this.gamebg.x, 80, ['This is your first time join the game.', "Let's choose a character"], LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		this.graphics = this.add.graphics();

		this.graphics.fillStyle(0x105aca, 0.5);
		this.graphics.fillRect(0, 200, this.gamebg.width, 100);
		this.characterName = this.add.text(this.gamebg.x, 250, '', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		this.graphics.fillStyle(0x01a9ea, 1);
		this.graphics.fillRect(this.topline.x - this.topline.width / 2, this.topline.y + 120, 430, 2);

		const bottomline = this.add.image(this.gamebg.x, 910, 'main2', 'character_stat_line');
		bottomline.scaleY = -1;
		const skillBg = this.add.image(this.topline.x - this.topline.width / 2 + 50, this.topline.y + 190, 'main2', 'skill_bg');

		var charArr = this.chars;

		var childPanel = this.rexUI.add.sizer({
			x: 0,
			y: 0,
			orientation: 'x',
			space: { item: 10, top: 10, bottom: 10 },
		});

		for (var i = 0; i < charArr.length; i++) {
			//var newBtn = new ChooseCharacterButton(this, 80 + i * 130, bottomline.y + 150, charArr[i].id);
			var newBtn = new ChooseCharacterButton(this, 0, 0, charArr[i].id);
			newBtn.fullName = charArr[i].name;
			newBtn.atk = charArr[i].atk;
			newBtn.def = charArr[i].def;
			newBtn.hp = charArr[i].hp;

			newBtn.on('pointerdown', function() {});
			if (i == 0) {
				newBtn.setSelected();
				this.selectChar(newBtn);
			}

			childPanel.add(newBtn);
		}

		var config = {
			x: this.gamebg.x,
			y: bottomline.y + 120,
			width: this.gamebg.width,
			height: 220,
			scrollMode: 1,
			slider: false,
			panel: {
				child: childPanel,
				mask: {
					padding: 0,
				},
			},
		};

		var panel = this.rexUI.add
			.scrollablePanel(config)
			.layout()
			.setOrigin(0.5, 0.5);

		panel
			.setChildrenInteractive({
				targets: [panel.getElement('panel')],
			})
			.on('child.click', function(child) {
				// child : Label from CreateItem()
				console.log(child);
				console.log(this.scene);
				this.scene.selectChar(child);
				child.setSelected();
			});

		var closeBtn = new IconButton(this, this.gamebg.x, bottomline.y + 250, 'bt_big_blue', 'OK');

		closeBtn.on('pointerup', async function() {
			this.disableBtn = true;
			await createNewChar(ChooseCharacterButton.currentSelectedBtn.btnName);
			this.disableBtn = false;
			this.scene.scene.start('MainScene');
			//this.scene.scene.stop('ChooseCharacterScene');
		});
	}

	selectChar(char) {
		if (this.character != null) {
			this.scene.remove(this.character);
			this.character.destroy();
			this.character = null;
		}

		this.character = this.add.spine(this.gamebg.x, 580, char.btnName + '-data', char.btnName + '-atlas');
		//this.character = this.add.spine(this.gamebg.x, 580, 'dogfly-data', 'dogfly-atlas');
		this.character.setOrigin(0.5, 0.5);
		this.character.scaleX = 0.5;
		this.character.scaleY = 0.5;
		const customSkin = new Skin('custom');

		this.character.skeleton.setSkin(customSkin);

		this.character.animationState.setAnimation(0, 'Idle', true);

		this.characterName.text = char.fullName;
		this.statsText['atk'].text = char.atk;
		this.statsText['def'].text = char.def;
		this.statsText['hp'].text = char.hp;
	}

	createStat(valueArr) {
		var labelArr = ['atk', 'def', 'hp'];
		this.statsText = [];

		for (var i = 0; i < valueArr.length; i++) {
			const atkIcon = this.add.image(this.topline.x - this.topline.width / 2 + 40 + 150 * i, this.topline.y + 25, 'main2', 'stat_' + labelArr[i]).setOrigin(0.5, 0);
			const atkText = this.add.text(atkIcon.x + atkIcon.width - 10, atkIcon.y, labelArr[i].toUpperCase(), LABEL_TEXT_CONFIG).setOrigin(0, 0);
			this.statsText[labelArr[i]] = this.add.text(atkText.x, atkText.y + 30, valueArr[i], BIG_LABEL_TEXT_CONFIG).setOrigin(0, 0);
		}
	}
}
