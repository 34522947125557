import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import ItemStatRow from '../components/ItemStatRow';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

import * as humanizeDuration from 'humanize-duration';

export default class OfflinePopup extends Phaser.Scene {
	constructor() {
		super('OfflinePopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'OfflinePopup' });
	}

	async init(data) {
		console.log(data);

		this.popupData = data;
	}

	preload() {}

	async create() {
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		r1.on('pointerup', function() {
			this.scene.scene.stop('OfflinePopup');
		});

		var popupBG = this.add.nineslice(360, 640, 'main', 'popup_bg', 600, 600, 40, 40, 40, 50);
		popupBG.setInteractive();

		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Offline Rewards', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var bigCoin = this.add.image(popupBG.x, popupBG.y - 30, 'main', 'token_energy_big');

		var leftTime = this.add.text(bigCoin.x, bigCoin.y - bigCoin.height, ['You have been left for:', humanizeDuration(this.popupData.offlineSecond * 1000)], LABEL_TEXT_CONFIG).setOrigin(0.5, 1);

		var total = this.add.text(bigCoin.x, bigCoin.y + bigCoin.height - 20, '+ ' + this.popupData.offlineReward, BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var sellButton = new PopupButton(this, popupBG.x, popupBG.y + popupBG.height / 2 - 70, 'button_green', 'OK');

		sellButton.on('pointerup', function() {
			this.scene.scene.stop('OfflinePopup');
		});

		//
	}
}
