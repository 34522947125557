export default class ProgressBar extends Phaser.GameObjects.Container {
	constructor(scene, x, y, color, maxNumber, trackLength = 200, atlas = 'main') {
		super(scene, x, y);
		this.btnName = color;
		this.trackLength = trackLength;
		this.maxNumber = maxNumber;
		scene.add.existing(this);

		this.bg = scene.add.nineslice(0, 0, atlas, 'progress_bg', trackLength, 0, 5, 5, 0, 0).setOrigin(0, 0);

		this.bar = scene.add.nineslice(0, 0, atlas, color, trackLength, 0, 5, 5, 0, 0).setOrigin(0, 0);

		this.setSize(trackLength, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.bar);
	}
	setProgress(newNumber) {
		this.bar.width = (newNumber / this.maxNumber) * this.trackLength;
		if (newNumber < 0) {
			this.bar.visible = false;
		} else {
			this.bar.visible = true;
		}
	}
}
