export default class EquipSlot extends Phaser.GameObjects.Container {
	constructor(scene, x, y, position, atlas = 'main') {
		super(scene, x, y);
		scene.add.existing(this);
		this.position = position;

		this.txt = scene.add.text(0, 0, '', { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0);
		this.bg = scene.add.image(0, 0, atlas, 'equip_none_bg');

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.txt);

		this.on('pointerover', function() {});

		this.on('pointerdown', function() {});

		this.on('pointerout', function() {});

		this.on('pointerup', function() {});

		this.txt.y = this.height / 2 - 10;
	}

	setItem(itemData) {
		this.itemData = itemData;
		if (this.itemThumb) {
			this.itemThumb.setTexture('main', this.position + '_' + itemData.image);
		} else {
			this.itemThumb = this.scene.add.image(0, 0, 'main', this.position + '_' + itemData.image);
			this.add(this.itemThumb);
		}
		var color = ['gray', 'green', 'blue', 'purple', 'red', 'yellow'];
		this.bg.setTexture('main', 'equip_rarity_' + color[this.itemData.rarity]);
	}

	removeItem() {}

	setRarely(color) {}

	set disableBtn(value) {
		this._disabled = value;

		if (value == true) {
			this.removeInteractive();
			//this.setTexture('mainmenu',this.btnName+"_disable");
			this.bg.setTexture('main', this.btnName + '_none');
		} else {
			this.setInteractive();
			this.bg.setTexture('main', this.btnName + '_none');
		}
	}
}
