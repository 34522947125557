export default class SimpleImageButton extends Phaser.GameObjects.Container {
	constructor(scene, x, y, name, atlas = 'main') {
		super(scene, x, y);
		this.btnName = name;
		scene.add.existing(this);
		this.bg = scene.add.image(0, 0, atlas, name);

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);

		this.on('pointerover', function() {});

		this.on('pointerdown', function() {
			this.bg.setScale(0.8, 0.8);
		});

		this.on('pointerout', function() {});

		this.on('pointerup', function() {
			this.bg.setScale(1, 1);
		});
	}
}
