export default class SlotButton extends Phaser.GameObjects.Container {
	constructor(scene, x, y, icon, text, textWidth = 150, atlas = 'main2') {
		super(scene, x, y);
		scene.add.existing(this);

		this.icon = scene.add.image(0, 0, atlas, icon + '_none');

		console.log(this.icon.height);

		//this.icon.x -= this.icon.width + 15;
		this.txt = scene.add.text(this.icon.width / 2 + 10, 0, text, { fontFamily: 'Barlow Condensed', fontSize: this.icon.height / 2 + 'px', fill: '#fff', align: 'left' }).setOrigin(0, 0.5);
		this.bg = scene.add.nineslice(0, 0, atlas, 'currency_bg', this.txt.width + this.icon.width, this.icon.height * 0.7, 5, 5, 5, 5).setOrigin(0, 0.5);
		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.icon);
		this.add(this.txt);

		this.on('pointerover', function() {
			this.icon.setTexture('main2', icon + '_active');
		});

		this.on('pointerdown', function() {
			this.icon.setTexture('main2', icon + '_active');
		});

		this.on('pointerout', function() {
			this.icon.setTexture('main2', icon + '_none');
		});

		this.on('pointerup', function() {
			this.icon.setTexture('main2', icon + '_active');
		});
	}
}
