export default class IconButton extends Phaser.GameObjects.Container {
	constructor(scene, x, y, name, text, icon = '', buttonWidth = 200, atlas = 'main') {
		super(scene, x, y);
		this.btnName = name;
		scene.add.existing(this);
		this.txt = scene.add.text(0, 0, text, { fontFamily: 'Barlow Condensed', fontSize: '26px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0.5);

		this.bg = scene.add.nineslice(0, 0, atlas, name + '_normal', buttonWidth, 0, 59, 59, 0, 0);

		this.isActiveIcon = icon != 'icon_copy';

		if (icon != '') {
			if (this.isActiveIcon) {
				this.icon = scene.add.image(0, 0, atlas, icon + '_normal').setOrigin(0.5, 0.5);
			} else {
				this.icon = scene.add.image(0, 0, atlas, icon).setOrigin(0.5, 0.5);
			}
		} else {
			this.isActiveIcon = false;
			this.icon = scene.add.container();
		}

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.txt);
		if (icon != '') this.add(this.icon);

		if (text != null && text != '') {
			console.log('this.txt.width', this.txt.width);
			this.icon.x = this.txt.width / 2 + this.icon.width / 2 + 10;
		}

		this.on('pointerover', function() {
			this.bg.setTexture('main', name + '_active');
			if (this.isActiveIcon) this.icon.setTexture('main', icon + '_active');
		});

		this.on('pointerdown', function() {
			this.bg.setTexture('main', name + '_active');
			if (this.isActiveIcon) this.icon.setTexture('main', icon + '_active');
		});

		this.on('pointerout', function() {
			this.bg.setTexture('main', name + '_normal');
			if (this.isActiveIcon) this.icon.setTexture('main', icon + '_normal');
		});

		this.on('pointerup', function() {
			this.bg.setTexture('main', name + '_active');
			if (this.isActiveIcon) this.icon.setTexture('main', icon + '_active');
		});
	}

	setText(value) {
		this.txt.text = value;
		this.icon.x = this.txt.width / 2 + this.icon.width / 2 + 10;
	}

	set disableBtn(value) {
		this._disabled = value;

		if (value == true) {
			this.removeInteractive();
			//this.setTexture('mainmenu',this.btnName+"_disable");
			this.bg.setTexture('main', this.btnName + '_disable');
		} else {
			this.setInteractive();
			this.bg.setTexture('main', this.btnName + '_normal');
		}
	}
}
