import Phaser from 'phaser';
import ImageButton from '../components/Button';
import SimpleImageButton from '../components/SimpleImageButton';
import IconButton from '../components/IconButton';
import EquipSlot from '../components/EquipSlot';
import SkillButton from '../components/SkillButton';
import SkillButtonBig from '../components/SkillButtonBig';
import FloatingNumbersPlugin from '../components/FloatingNumbersPlugin';
import ProgressBar from '../components/ProgressBar';
import CurrencySlot from '../components/CurrencySlot';
import SlotButton from '../components/SlotButton';
import TonConnect from '@tonconnect/sdk';
import { TonConnectUI, toUserFriendlyAddress } from '@tonconnect/ui';
import { beginCell, toNano, TonClient } from '@ton/ton';
import { Address, OpenedContract } from '@ton/core';
import PopupButton from '../components/PopupButton';
import ProfitButton from '../components/ProfitButton';

import { DogMutant } from '../contracts/tact_DogMutant';

export default class MainScene extends Phaser.Scene {
	constructor() {
		super('MainScene');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'MainScene' });
	}

	preload() {
		this.load.scenePlugin('floatingNumbersPlugin', FloatingNumbersPlugin, 'floatingNumbersPlugin', 'floatingNumbers');
	}

	createNumber(targetObj, divide = 1) {
		if (this.currentTurn >= this.battleInfo.length) {
			return;
		}

		//console.log('current turn' + this.currentTurn);
		//console.log('battleInfo' + this.battleInfo.length);

		var dognumber = this.floatingNumbers.createFloatingText({
			textOptions: {
				fontFamily: 'Barlow Condensed',
				fontSize: 42,
				color: '#fff',
				strokeThickness: 2,
				fontWeight: 'bold',
				stroke: '#000000',
				shadow: {
					offsetX: 0,
					offsetY: 0,
					color: '#000',
					blur: 4,
					stroke: true,
					fill: false,
				},
			},
			text: Math.floor(this.battleInfo[this.currentTurn].damage / divide),
			align: 'top-center',
			parentObject: targetObj,
			animation: 'up',
			timeToLive: 500,
			animationEase: 'Sine.easeOut',
		});
	}

	timerEvent() {
		if (this.onBattle == false) {
			return;
		}

		if (this.battleInfo == null || this.battleInfo.length == 0 || this.currentTurn >= this.battleInfo.length - 1) {
			this.resetBattleField();

			this.loadPlayerData();

			if (!this.isLoading) {
				//	this.loadNewOpponent();
			}

			return;
		}
		this.currentTurn += 1;

		var attackChar = null;
		var hitChar = null;
		var attackSkill = null;
		var hpTrack = null;
		var skillBtn = null;
		var skillData = null;

		if (this.currentTurn % 2 == 0) {
			attackChar = this.rightPlayer;
			attackSkill = this.rightSkill;
			hitChar = this.leftPlayer;
			hpTrack = this.leftHP;
			skillBtn = this.leftSkill;
			skillData = this.rightSkillData;
		} else {
			attackChar = this.leftPlayer;
			attackSkill = this.leftSkill;
			hitChar = this.rightPlayer;
			hpTrack = this.rightHP;
			skillBtn = this.rightSkill;
			skillData = this.leftSkillData;
		}

		attackChar.animationState.addListener({
			complete: entry => {
				attackChar.animationState.clearListeners();
				attackChar.animationState.setAnimation(0, 'Idle', true);
				this.isUpdatingCoin = false;
			},
			event: (entry, event) => {
				if (event.data.name == 'Hit') {
					if (this.battleInfo[this.currentTurn].isSkill && skillData.impact != null) {
						var impact = this.add.spine(hitChar.x, hitChar.y, skillData.impact.toString() + '-data', skillData.impact.toString() + '-atlas');

						if (skillData.impact_position == 'top') {
							impact.y -= 170;
						}

						impact.animationState.addListener({
							complete: entry => {
								this.scene.remove(impact);
								impact.destroy();
							},
						});

						impact.setOrigin(0.5, 0.5);
						impact.scaleX = 0.5;
						impact.scaleY = 0.5;
						impact.animationState.setAnimation(0, 'Skill', false);
					}
					if (this.battleInfo[this.currentTurn].isSkill) {
						this.createNumber(hitChar, skillData.hit);

						//console.log('Dogzila hit ' + JSON.stringify(event));
					} else {
						this.createNumber(hitChar);
					}

					if (hitChar == this.rightPlayer) {
						if (this.battleInfo[this.currentTurn].isSkill) {
							this.createReward(Math.floor(this.battleInfo[this.currentTurn].coinDrop / skillData.hit), this.battleInfo[this.currentTurn].coinDrop);
						} else {
							this.createReward(this.battleInfo[this.currentTurn].coinDrop, this.battleInfo[this.currentTurn].coinDrop);
						}
					}

					hpTrack.setProgress(this.battleInfo[this.currentTurn].newHP);
					skillBtn.setProgress(this.battleInfo[this.currentTurn].skillGauge);

					if (this.battleInfo[this.currentTurn].newHP < 0) {
						hitChar.animationState.setAnimation(0, 'Die', false);
					} else {
						hitChar.animationState.setAnimation(0, 'Hit', false);
						hitChar.animationState.addAnimation(0, 'Idle', true);
					}
				}
			},
		});

		if (this.battleInfo[this.currentTurn].isSkill) {
			attackChar.animationState.setAnimation(0, skillData.animation, false);

			attackSkill.resetSkill();
		} else {
			attackChar.animationState.setAnimation(0, 'NormalAttack', false);
		}
	}

	create() {
		const bgNumber = 1 + Math.floor(Math.random() * 5);
		this.gamebg = this.add.image(0, 0, 'bg-' + bgNumber);
		this.gamebg.x = this.gamebg.width / 2;
		this.gamebg.y = this.gamebg.height / 2;

		this.bottomBar = this.add.image(0, 0, 'main2', 'bottom_menu_bg');

		this.bottomBar.scaleX = this.gamebg.width / this.bottomBar.width;
		this.bottomBar.x = (this.bottomBar.width * this.bottomBar.scaleX) / 2;
		this.bottomBar.y = this.gamebg.height - this.bottomBar.height;

		this.inventorybg = this.add.image(0, 0, 'main2', 'player_equipments_bg');
		this.inventorybg.x = this.gamebg.x;
		this.inventorybg.y = this.bottomBar.y - this.bottomBar.height / 2 - this.inventorybg.height / 2;

		this.staminaSlot = new CurrencySlot(this, 120, 50, 'character_energy');

		var connectButtonLabel = 'Connect Wallet';
		if (tonConnectUI.walletInfo != null) {
			console.log('tonConnectUI', tonConnectUI.account.address);
			const friendlyFormat = toUserFriendlyAddress(tonConnectUI.account.address);
			console.log(friendlyFormat);
			connectButtonLabel = friendlyFormat.substr(0, 4) + '....' + friendlyFormat.substr(-4, 4);
		}

		this.connectButton = new SlotButton(this, 280, this.staminaSlot.y, 'ton_wallet', connectButtonLabel);

		const unsubscribe = tonConnectUI.onStatusChange(walletAndwalletInfo => {
			console.log('Wallet Info');
			console.log(walletAndwalletInfo);
		});

		this.coinSlot = new CurrencySlot(this, this.gamebg.x, 120, 'token_energy', 200);
		this.coinSlot.visible = false;

		this.profitButtonLeft = new ProfitButton(this, 120, this.coinSlot.y, 'earn_button', 'Earn per hit', 3);
		this.profitButtonRight = new ProfitButton(this, this.gamebg.width - 120, this.coinSlot.y, 'earn_button', 'Earn per win', 20);

		this.shiba = this.add.spine(360, 1020, 'mutantdog-data', 'mutantdog-atlas');
		this.shiba.animationState.setAnimation(0, 'Idle_01', true);
		this.shiba.scaleX = 0.5;
		this.shiba.scaleY = 0.5;

		this.mutantmachine = this.add.image(0, 0, 'main2', 'mutant_machine');
		this.mutantmachine.x = this.gamebg.x;
		this.mutantmachine.y = this.bottomBar.y - 100;

		this.mutantPriceBg = this.add.nineslice(this.mutantmachine.x, this.mutantmachine.y + 20, 'main', 'mutant_currency_bg', 90, 0, 35, 16, 0, 0);
		this.mutantPriceText = this.add.text(this.mutantPriceBg.x + 10, this.mutantPriceBg.y, '', { fontFamily: 'Barlow Condensed', fontSize: '26px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0.5);

		//this.button0 = new SimpleImageButton(this, this.gamebg.x, this.bottomBar.y, 'mutant_text');

		this.countDownText = this.add.text(this.gamebg.x, 400, '', { fontFamily: 'Barlow Condensed', fontSize: '36px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0.5);

		this.button0 = new IconButton(this, this.gamebg.x, this.bottomBar.y, 'bt_big_blue', '', 'mutant_text', 180);

		this.button1 = new ImageButton(this, 50, this.bottomBar.y - 30, 'menu_shop', 'Shop', 'main2', '_none');
		this.button2 = new ImageButton(this, 135, this.bottomBar.y - 30, 'menu_mine', 'Mine', 'main2', '_normal');
		this.button3 = new ImageButton(this, 220, this.bottomBar.y - 30, 'menu_earn', 'Earn', 'main2', '_normal');

		this.button4 = new ImageButton(this, this.gamebg.width - this.button3.x, this.bottomBar.y - 30, 'menu_arena', 'Arena', 'main2', '_normal');
		this.button5 = new ImageButton(this, this.gamebg.width - this.button2.x, this.bottomBar.y - 30, 'menu_friends', 'Friends', 'main2', '_normal');
		this.button6 = new ImageButton(this, this.gamebg.width - this.button1.x, this.bottomBar.y - 30, 'menu_airdrop', 'Airdrop', 'main2', '_normal');

		this.headEquip = new EquipSlot(this, 170, 800, 'head');
		this.bodyEquip = new EquipSlot(this, this.headEquip.x, this.headEquip.y + 100, 'body');
		this.footEquip = new EquipSlot(this, this.headEquip.x, this.bodyEquip.y + 100, 'leg');
		this.leftHandEquip = new EquipSlot(this, this.headEquip.x + 100, this.bodyEquip.y, 'hand_left');
		this.rightHandEquip = new EquipSlot(this, this.headEquip.x - 100, this.bodyEquip.y, 'hand_right');

		this.button0.on('pointerup', event => {
			this.loadNewItem();
		});

		this.button1.disableBtn = true;

		this.button1.on('pointerup', event => {
			//this.loadShopView();
		});

		this.button3.on('pointerup', event => {
			this.loadReferralView();
		});

		this.button4.on('pointerup', event => {
			this.loadLeaderboardView();
		});

		this.button2.on('pointerup', event => {
			this.loadLeaderboardView();
		});

		this.connectButton.on('pointerup', event => {
			this.loadTonConnect();
		});

		this.battleInfo = [];
		this.currentTurn = -1;

		//this.loadLeaderboardView();
		//var newPopupScene = this.scene.launch('OfflinePopup', { offlineSecond: 123111135, offlineReward: 30 });
		//return;

		this.loadPlayerData();

		this.time.addEvent({
			callback: function() {
				if (this.staminaSlot.visible == true) {
					if (this.staminaSlot.maxNumber != null && this.staminaSlot.currentNumber < this.staminaSlot.maxNumber) {
						this.staminaSlot.updateNumber(this.staminaSlot.currentNumber + 1);
					}
				}
			},
			callbackScope: this,
			delay: 1000, // 1000 = 1 second
			loop: true,
		});

		//this.loadShopView();

		return;

		this.loadNewOpponent();
	}

	async loadCharsPopup() {
		const listChars = await getListChar();
		this.scene.start('ChooseCharacterScene', listChars);
	}

	resetBattleField() {
		const bgNumber = 1 + Math.floor(Math.random() * 5);
		this.gamebg = this.gamebg.setTexture('bg-' + bgNumber);

		if (this.startButton) {
			this.startButton.destroy();
		}

		if (this.autoButton) {
			this.autoButton.destroy();
		}

		if (this.leftPlayer) {
			this.leftPlayer.destroy();
		}

		if (this.rightPlayer) {
			this.rightPlayer.destroy();
		}

		if (this.myName) {
			this.myName.destroy();
		}

		if (this.oppName) {
			this.oppName.destroy();
		}

		if (this.leftSkill) {
			this.leftSkill.destroy();
		}

		if (this.rightSkill) {
			this.rightSkill.destroy();
		}

		if (this.leftHP) {
			this.leftHP.destroy();
		}

		if (this.rightHP) {
			this.rightHP.destroy();
		}
		this.battleInfo = null;
		this.currentTurn = -1;
	}

	async loadReferralView() {
		var newPopupScene = this.scene.launch('ReferralPopup');
	}

	async loadLeaderboardView() {
		var newPopupScene = this.scene.launch('LeaderboardPopup');
	}

	async loadShopView() {
		var newPopupScene = this.scene.launch('ShopPopup');
	}

	async loadNewItem() {
		if (!this.isLoading) {
			this.isLoading = true;
		} else {
			return;
		}

		this.shiba.animationState.setAnimation(0, 'Action_01', true);
		this.button0.disableBtn = true;
		this.shibaLoading = true;

		const itemResult = await summonNewItem();

		console.log('itemResult', itemResult);

		if (itemResult.data == false || itemResult.data.success == false) {
			this.button0.disableBtn = false;
			this.checkMutantButtonDisable();
			this.shiba.animationState.setAnimation(0, 'Idle_01', true);
			this.shibaLoading = false;
			this.isLoading = false;
			return;
		}

		console.log('Come to here', itemResult.data);

		var currentCoinNumber = parseInt(this.coinSlot.currentNumber);

		var itemPrice = parseInt(itemResult.data.price);

		this.coinSlot.updateNumber(currentCoinNumber - itemResult.data.price);
		this.mutantPrice = itemResult.data.price;
		this.mutantPriceText.text = this.mutantPrice;

		const oldItem = this.myEquipments[itemResult.data.position];
		var updateObj = { oldItem: oldItem, newItem: itemResult.data };

		this.shiba.animationState.setAnimation(0, 'Action_02', false);
		this.shiba.animationState.addListener({
			complete: entry => {
				this.shibaLoading = false;
				this.shiba.animationState.clearListeners();
				this.button0.setInteractive();
				this.button0.disableBtn = false;
				this.checkMutantButtonDisable();
			},
			event: (entry, event) => {
				if (event.data.name == 'big') {
					var newPopupScene = this.scene.launch('NewEquipPopup', updateObj);
					console.log(newPopupScene);
					this.isLoading = false;
				}
			},
		});

		this.shiba.animationState.addAnimation(0, 'Idle_01', true);
	}

	checkMutantButtonDisable() {
		if (this.shibaLoading != null && this.shibaLoading == true) {
			return;
		}
		if (this.coinSlot.currentNumber < this.mutantPrice) {
			this.button0.disableBtn = true;
		} else {
			this.button0.disableBtn = false;
		}
	}

	updateEquipments() {
		if (this.myEquipments == null) return;
		if (this.myEquipments.head) {
			this.headEquip.setItem(this.myEquipments.head);
		}
		if (this.myEquipments.body) {
			this.bodyEquip.setItem(this.myEquipments.body);
		}
		if (this.myEquipments.leg) {
			this.footEquip.setItem(this.myEquipments.leg);
		}
		if (this.myEquipments.hand_left) {
			this.leftHandEquip.setItem(this.myEquipments.hand_left);
		}
		if (this.myEquipments.hand_right) {
			this.rightHandEquip.setItem(this.myEquipments.hand_right);
		}
	}

	async loadTonConnect() {
		if (tonConnectUI.walletInfo == null) {
			tonConnectUI.openModal();
			return;
		}

		const client = new TonClient({
			endpoint: 'https://toncenter.com/api/v2/jsonRPC',
		});

		const contract = new DogMutant(
			Address.parse('EQDMJVS5Fpxy1eWVDpG3R312Tkp00hIoldDX4OOSQWMG7C00') // replace with your address from tutorial 2 step 8
		);

		var openedContract = client.open(contract);
		var address = openedContract?.address.toString();
		console.log(address);

		const body = beginCell()
			.storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
			.storeStringTail('Checkin!') // write our text comment
			.endCell();

		const transaction = {
			validUntil: Math.floor(Date.now() / 1000) + 360,
			messages: [
				{
					address: address,
					amount: toNano(0.008).toString(),
					payload: body.toBoc().toString('base64'), // payload with comment in body
				},
			],
		};

		const result = await tonConnectUI.sendTransaction(transaction);

		//
	}

	async loadPlayerData() {
		this.onBattle = false;
		//this.isLoading = true;

		this.startButton = new PopupButton(this, 250, 630, 'button_red', 'BATTLE', 150);
		const staminaPerBattleTxt = this.add.text(10, -50, '300', { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0.5);
		const staminaPerBattleBg = this.add.nineslice(0, staminaPerBattleTxt.y, 'main2', name + 'energy_currency_bg', 100, 0, 35, 15, 0, 0).setOrigin(0.5, 0.5);

		this.autoButton = new PopupButton(this, 450, this.startButton.y, 'button_orange', 'AUTO', 150);

		this.startButton.add(staminaPerBattleBg);
		this.startButton.add(staminaPerBattleTxt);

		this.startButton.on('pointerup', event => {
			this.resetBattleField();
			this.loadNewOpponent();
		});

		const characterData = await getMyCharacter();

		console.log(characterData);

		if (characterData.success == false) {
			await this.loadCharsPopup();
			return;
		} else {
			this.gameSpeed = 1;
			if (characterData.data.hasOwnProperty('speed')) {
				this.gameSpeed = characterData.data.speed;
				if (this.gameSpeed > 4) {
					this.gameSpeed = 4;
				}
			}

			this.leftPlayer = this.add.spine(this.gamebg.x, 500, characterData.data.skin + '-data', characterData.data.skin + '-atlas');
			this.leftPlayer.setOrigin(0.5, 0.5);
			this.leftPlayer.skinName = characterData.data.skin;
			this.leftPlayer.scaleX = 0.5;
			this.leftPlayer.scaleY = 0.5;
			this.leftPlayer.animationState.setAnimation(0, 'Idle', true);
			this.leftPlayer.animationState.timeScale = this.gameSpeed;

			this.myName = this.add.text(this.leftPlayer.x, this.leftPlayer.y - 240, characterData.data.displayName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'left' }).setOrigin(0.5, 0);

			this.myEquipments = characterData.data.equipments;
			this.updateEquipments();

			this.coinSlot.visible = true;
			this.coinSlot.updateNumber(characterData.data.displayCoin);

			this.staminaSlot.maxNumber = characterData.data.maxStamina;
			this.staminaSlot.updateNumber(characterData.data.stamina);

			this.normalAtk = new SkillButtonBig(this, 440, this.headEquip.y, 'normal_attack_1', 0);
			this.normalAtk.radius = 360;

			this.triggerTimer = this.time.addEvent({
				callback: this.timerEvent,
				callbackScope: this,
				delay: 4000 / this.gameSpeed, // 1000 = 1 second
				loop: true,
			});
		}
	}

	async loadNewOpponent() {
		this.isLoading = true;

		const battleResult = await startNewBattle();

		this.mutantPrice = 100;
		this.mutantPriceText.text = this.mutantPrice;

		console.log(battleResult);

		if (battleResult.data.success == false) {
			if (battleResult.data.hasOwnProperty('playerExists') && battleResult.data.playerExists == false) {
				await this.loadCharsPopup();
				return;
			}

			this.countDownText.visible = true;
			this.coinSlot.visible = false;

			this.countDownTween = this.tweens.addCounter({
				from: battleResult.data.wait_second,
				to: 0,
				duration: battleResult.data.wait_second * 1000,
				ease: 'linear',
				onUpdate: tween => {
					const value = Math.round(tween.getValue());
					this.countDownText.text = 'Next battle start in: ' + value;
				},

				onComplete: tween => {
					this.countDownText.text = 'Loading new battle...';
					this.loadNewOpponent();
				},
			});

			return;
		}
		this.countDownText.visible.false;
		this.countDownText.text = '';
		this.coinSlot.visible = true;

		this.isLoading = false;

		this.coinSlot.updateNumber(battleResult.data.myData.displayCoin);

		this.staminaSlot.maxNumber = battleResult.data.myData.maxStamina;
		this.staminaSlot.updateNumber(battleResult.data.myData.stamina);

		if (battleResult.data.offlineReward != null && battleResult.data.offlineReward > 0) {
			if (battleResult.data.offlineSecond != null && battleResult.data.offlineSecond > 90) {
				var newPopupScene = this.scene.launch('OfflinePopup', { offlineReward: battleResult.data.offlineReward, offlineSecond: battleResult.data.offlineSecond });
			}
		}

		this.checkMutantButtonDisable();
		this.myEquipments = battleResult.data.myData.equipments;
		this.updateEquipments();

		this.onBattle = true;

		if (battleResult.data.opponent) {
			this.currentTurn = -1;
			this.isUpdatingCoin = false;
			this.battleInfo = battleResult.data.battleInfo;

			this.leftPlayer = this.add.spine(150, 500, battleResult.data.myData.skin + '-data', battleResult.data.myData.skin + '-atlas');
			this.leftPlayer.setOrigin(0.5, 0.5);
			this.leftPlayer.skinName = battleResult.data.myData.skin;
			this.leftPlayer.scaleX = 0.5;
			this.leftPlayer.scaleY = 0.5;
			this.leftPlayer.animationState.setAnimation(0, 'Idle', true);
			this.leftPlayer.animationState.timeScale = this.gameSpeed;

			this.rightPlayer = this.add.spine(550, 500, battleResult.data.opponentData.skin + '-data', battleResult.data.opponentData.skin + '-atlas');
			this.rightPlayer.setOrigin(0.5, 0.5);
			this.rightPlayer.skinName = battleResult.data.opponentData.skin;
			this.rightPlayer.scaleX = -0.5;
			this.rightPlayer.scaleY = 0.5;
			this.rightPlayer.animationState.setAnimation(0, 'Idle', true);
			this.rightPlayer.animationState.timeScale = this.gameSpeed;

			const leftSkillId = battleResult.data.myData.skill.id;
			const rightSkillId = battleResult.data.opponentData.skill.id;

			for (var i = 0; i < battleResult.data.setting.skills.length; i++) {
				if (battleResult.data.setting.skills[i].id == leftSkillId) {
					this.leftSkillData = battleResult.data.setting.skills[i];
				}
				if (battleResult.data.setting.skills[i].id == rightSkillId) {
					this.rightSkillData = battleResult.data.setting.skills[i];
				}
			}

			this.leftSkill = new SkillButton(this, 150, 600, this.leftSkillData.thumb, battleResult.data.myData.skill.dmg_gauge);
			this.rightSkill = new SkillButton(this, 550, 600, this.rightSkillData.thumb, battleResult.data.opponentData.skill.dmg_gauge);

			this.leftHP = new ProgressBar(this, 50, 200, 'hp_green', battleResult.data.myData.hp);
			this.rightHP = new ProgressBar(this, 470, 200, 'hp_red', battleResult.data.opponentData.hp);

			this.myName = this.add.text(this.leftHP.x, this.leftHP.y - 40, battleResult.data.myData.displayName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'left' }).setOrigin(0, 0);

			console.log('My name2');

			this.oppName = this.add.text(this.rightHP.x + this.rightHP.width, this.rightHP.y - 40, battleResult.data.opponentData.displayName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'right' }).setOrigin(1, 0);
		}
	}

	createReward(maxParticle = 5, newTotalCoin = -1) {
		const emitter = this.add.particles(550, 500, 'main', {
			frame: 'token_energy',
			bounce: 0.5,
			speed: { min: 600, max: 700 },
			maxVelocityX: 1200,
			maxVelocityY: 1200,
			angle: { min: -100, max: -80 },
			gravityY: 1600,
			//y: { start: 30, end: 500, ease: 'bounce.out' },
			lifespan: 4000,

			maxParticles: maxParticle,
			sortProperty: 'lifeT',
			sortOrderAsc: true,
		});

		emitter.addParticleBounds(0, 0, 700, 650, false, false, false, true);

		this.time.addEvent({
			callback: function() {
				emitter.gravityY = 0;
				const well = emitter.createGravityWell({
					x: -550,
					y: -550,
					power: 10,
					epsilon: 10,
					gravity: 1600,
				});
				if (newTotalCoin >= 0 && this.isUpdatingCoin == false) {
					this.isUpdatingCoin = true;
					const currentNumber = this.coinSlot.currentNumber;
					this.coinSlot.updateNumber(currentNumber + newTotalCoin);
					this.checkMutantButtonDisable();
				}
				//
			},
			callbackScope: this,
			delay: 2000, // 1000 = 1 second
			loop: false,
		});
	}

	getSkillName(skinName) {
		if (skinName == 'mechzilla') {
			return 'rocket';
		} else if (skinName == 'dogzilla') {
			return 'skill_2';
		} else {
			return 'skill_1';
		}
	}
}
