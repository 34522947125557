export default class SkillButton extends Phaser.GameObjects.Container {
	constructor(scene, x, y, name, maxNumber = 0, atlas = 'main') {
		super(scene, x, y);
		this.skillName = name;
		this.maxNumber = maxNumber;
		scene.add.existing(this);

		this.skillBg = scene.add.image(0, 0, atlas, 'skill_bg');
		this.bg = scene.add.image(0, 0, atlas, name);

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.graphics = scene.add.graphics();
		this.add(this.skillBg);
		this.add(this.bg);
		this.add(this.graphics);

		this.resetSkill();

		this.triggerTimer = scene.time.addEvent({
			callback: this.timerEvent,
			callbackScope: this,
			delay: 5, // 1000 = 1 second
			loop: true,
		});
	}

	resetSkill() {
		this.runningRadius = -90;
		this.radius = 0;
		this.graphics.clear();
		this.graphics.fillStyle(0x000000, 0.7);
		this.graphics.slice(0, 0, this.bg.width / 2 - 6, Phaser.Math.DegToRad(-90), Phaser.Math.DegToRad(-89.9), true);
		this.graphics.fillPath();
	}

	setProgress(newNumber) {
		this.radius = (newNumber / this.maxNumber) * 360;
	}

	timerEvent() {
		if (this.runningRadius >= this.radius - 90) {
			return;
		}
		this.graphics.clear();
		if (this.radius >= 359.9) {
			return;
		}
		this.runningRadius += 1;
		this.graphics.fillStyle(0x000000, 0.7);
		this.graphics.slice(0, 0, this.bg.width / 2 - 6, Phaser.Math.DegToRad(-90), Phaser.Math.DegToRad(this.runningRadius), true);
		this.graphics.fillPath();
	}
}
