import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import ItemStatRow from '../components/ItemStatRow';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

export default class ShopPopup extends Phaser.Scene {
	constructor() {
		super('ShopPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'ShopPopup' });
	}

	async init(data) {
		console.log(data);

		//this.compareData = data;
	}

	preload() {}

	async create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		r1.on('pointerup', function() {
			this.scene.scene.stop('ShopPopup');
		});

		var popupBG = this.add.nineslice(360, 640, 'main', 'popup_bg', 650, 1000, 40, 40, 40, 50);
		popupBG.setInteractive();

		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Shop', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var childPanel = this.rexUI.add.gridSizer({
			x: 0,
			y: 0,
			column: 2,
			row: 4,
			//orientation: 'y',
			space: { left: 50, right: 30, column: 30, row: 60 },
		});

		const shopItemData = await getInappProducts();

		console.log(shopItemData);

		for (var i = 0; i < shopItemData.data.length; i++) {
			childPanel.add(this.createItemRenderer(i + 1, shopItemData.data[i]));
		}

		//var abc = this.createItemRenderer();
		childPanel.setInteractive({ dropZone: true });

		var config = {
			x: popupBG.x,
			y: popupBG.y,
			width: popupBG.width,
			height: popupBG.height - 150,
			scrollMode: 0,
			slider: false,
			panel: {
				child: childPanel,
				mask: {
					padding: 0,
				},
			},
		};

		var panel = this.rexUI.add
			.scrollablePanel(config)
			.layout()
			.setOrigin(0.5, 0.5);

		panel.setChildrenInteractive().on('child.click', async function(child) {
			if (this.isDisable == true) {
				return;
			}
			if (child.inappData.id == 'inapp1') {
				return;
			}

			console.log(child);

			child.button.disableBtn = true;
			this.isDisable = true;
			var invoiceLink = await buyInappProduct(child.inappData.id);

			await gotoTelegramLink(invoiceLink.data);

			child.button.disableBtn = false;
			this.isDisable = false;

			//gotoTelegramLink('https://t.me/$VQNgakEukFcIAQAAYmDOYyrx1ig');
		});

		panel.setChildrenInteractive().on('child.pressstart', function(child) {
			console.log(child);
			child.button.emit('pointerdown');
		});

		//
	}

	async buyProduct(productId) {
		console.log('huhuhuh123123');
	}

	createItemRenderer(index, data) {
		var container = this.add.container();
		container.inappData = data;

		var itemBg = this.add.nineslice(0, 0, 'main2', 'earn_button_none', 260, 250, 3, 3, 3, 3);
		container.add(itemBg);
		container.setSize(itemBg.width, itemBg.height);
		container.setInteractive();

		var light = this.add.image(itemBg.x, itemBg.y - 30, 'main2', 'shop_light_fx').setScale(2, 2);
		container.add(light);

		var icon = this.add.image(light.x, light.y, 'main2', data.thumb);
		container.add(icon);

		var textBanner = this.add.rectangle(0, 50, itemBg.width, 50, 0x011e45, 0.8).setOrigin();
		container.add(textBanner);

		var name = this.add.text(textBanner.x, textBanner.y, data.name, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff200', align: 'left' }).setOrigin(0.5, 0.5);
		container.add(name);

		var buyButton = new PopupButton(this, 0, itemBg.height / 2, 'button_green', data.label);
		buyButton.setInteractive();
		container.button = buyButton;
		buyButton.on('pointerup', event => {
			//
		});

		if (data.hasOwnProperty('badge')) {
			var badgeBg = this.add.image(-itemBg.width / 2 + 40, -itemBg.height / 2 + 40, 'main2', 'sale_badge_orange');
			container.add(badgeBg);
			var badgeTxt = this.add.text(badgeBg.x, badgeBg.y, data.badge, { wordWrap: { width: badgeBg.width }, fontFamily: 'Barlow Condensed', fontSize: '25px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);
			badgeTxt.angle = -25;

			container.add(badgeTxt);
		}

		/*var indexTxt = this.add.text(-250, 0, index, { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);

		if (index < 4) {
			var medal = this.add.image(indexTxt.x, indexTxt.y, 'main', 'medal_' + index);
			container.add(medal);
		}

		var name = this.add.text(-150, -38, data.name, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'left' }).setOrigin(0, 0);
		var elo = this.add.text(-150, -5, 'Elo: ' + data.elo.toString(), { fontFamily: 'Barlow Condensed', fontSize: '35px', fill: 'yellow', align: 'left' }).setOrigin(0, 0);

		container.add(name);
		container.add(elo);
		//
		container.add(indexTxt);*/

		container.add(buyButton);

		return container;
	}
}
