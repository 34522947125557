import Phaser from 'phaser';
import MainScene from './scenes/MainScene';
import Boot from './scenes/Boot';
import Preloader from './scenes/Preloader';
import LeaderboardPopup from './scenes/LeaderboardPopup';
import ShopPopup from './scenes/ShopPopup';
import ChooseCharacterScene from './scenes/ChooseCharacterScene';
import OfflinePopup from './scenes/OfflinePopup';
import NewEquipPopup from './scenes/NewEquipPopup';
import ReferralPopup from './scenes/ReferralPopup';
import WebFontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin.js';
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import EaseMovePlugin from 'phaser3-rex-plugins/plugins/easemove-plugin.js';
import { SpinePlugin } from '@esotericsoftware/spine-phaser';
import { TonConnectUI } from '@tonconnect/ui';
import eruda from 'eruda';
import axios from 'axios';

// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, signInWithCustomToken, updateProfile } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyCyUz2g1TuunmtR9dCZfCBIVaR1tHiOIck',
	authDomain: 'dog-mutant.firebaseapp.com',
	projectId: 'dog-mutant',
	storageBucket: 'dog-mutant.appspot.com',
	messagingSenderId: '295690567270',
	appId: '1:295690567270:web:efe6494d13213c7a17b7a7',
	measurementId: 'G-YC13HQXJ3J',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

var currentPendingItem = null;

tonConnectUI = new TonConnectUI({
	manifestUrl: 'https://localwebapp2.dogmutant.com/tonconnect-manifest.json',
});
console.log('tonccnet', tonConnectUI);

//const addMessage = httpsCallable(functions, 'addMessage');

eruda.init();
Telegram.WebApp.expand();
console.log(Telegram.WebApp.initData);
console.log('ahuhuh');
console.log(Telegram.WebApp.initDataUnsafe);

getData = async () => {
	console.log(Telegram.WebApp.initData);

	const response = await axios.post(
		'https://helloworld-t75wjesv6a-uc.a.run.app?data=' + btoa(Telegram.WebApp.initData),
		//'https://local.dogmutant.com/dog-mutant/us-central1/helloWorld?data=' + btoa(Telegram.WebApp.initData),
		{
			firstName: 'Fred',
			lastName: 'Flintstone',
			authenData: Telegram.WebApp.initData,
		},
		{
			headers: {
				'authen-data': Telegram.WebApp.initData,
			},
		}
	);
	const token = response.data;

	const auth = getAuth();
	const userCredential = await signInWithCustomToken(auth, token);

	console.log(userCredential);

	await updateProfile(auth.currentUser, {
		displayName: Telegram.WebApp.initDataUnsafe.user.first_name + ' ' + Telegram.WebApp.initDataUnsafe.user.last_name,
	});

	return userCredential.user;
};

startBattle = async () => {
	const startBattle = httpsCallable(functions, 'startBattle');
	const result = await startBattle();
	//console.log(result.data);
	return result.data;
};

startNewBattle = async () => {
	const startNewBattle = httpsCallable(functions, 'startNewBattle');
	const result = await startNewBattle();
	//console.log(result.data);
	return result.data;
};

getMyReferral = async () => {
	const myReferral = httpsCallable(functions, 'myReferral');
	const result = await myReferral();
	//console.log(result.data);
	return result.data;
};

getMyReferralLink = async () => {
	const getMyRefLink = httpsCallable(functions, 'getMyRefLink');
	const result = await getMyRefLink();
	//console.log(result.data);
	return result.data;
};

summonItem = async () => {
	const summonItem = httpsCallable(functions, 'summonItem');
	const result = await summonItem();
	//console.log(result.data);
	return result.data;
};

summonNewItem = async () => {
	const summonItem = httpsCallable(functions, 'summonNewItem');
	const result = await summonItem();
	//console.log(result.data);
	return result.data;
};

getMyCharacter = async () => {
	const getMyCharacter = httpsCallable(functions, 'getMyCharacter');
	const result = await getMyCharacter();
	//console.log(result.data);
	return result.data;
};

createNewChar = async selectedChar => {
	const createNewChar = httpsCallable(functions, 'createNewChar');
	console.log(createNewChar);
	const result = await createNewChar({ selectedChar: selectedChar });
	console.log(result.data);
	return result.data;
};

buyInappProduct = async inAppId => {
	const buyProduct = httpsCallable(functions, 'buyProduct');

	const result = await buyProduct({ inAppId: inAppId });

	return result.data;
};

gotoTelegramLink = async url => {
	Telegram.WebApp.openTelegramLink(url);
};

equipPendingItem = async () => {
	const equipPendingItem = httpsCallable(functions, 'equipPendingItem');
	const result = await equipPendingItem();
	console.log(result.data);
	return result.data;
};

equipPendingItemNew = async () => {
	const equipPendingItem = httpsCallable(functions, 'equipPendingItemNew');
	const result = await equipPendingItem();

	return result.data;
};

getLeaderboard = async () => {
	const getLeaderboard = httpsCallable(functions, 'getLeaderboard');
	const result = await getLeaderboard();
	console.log(result.data);
	return result.data;
};

getInappProducts = async () => {
	const getInappProducts = httpsCallable(functions, 'getInappProducts');
	const result = await getInappProducts();
	console.log(result.data);
	return result.data;
};

getListChar = async () => {
	const getListChar = httpsCallable(functions, 'getListChar');
	const result = await getListChar();
	console.log(result.data);
	return result.data;
};

const config = {
	type: Phaser.AUTO,
	width: 720,
	height: 1280,
	scene: [Boot, Preloader, MainScene, ChooseCharacterScene, NewEquipPopup, ReferralPopup, LeaderboardPopup, OfflinePopup, ShopPopup],
	scale: {
		// Fit to window
		mode: Phaser.Scale.FIT,
		// Center vertically and horizontally
		//autoCenter: Phaser.Scale.CENTER_BOTH,
	},
	plugins: {
		scene: [
			{ key: 'spine.SpinePlugin', plugin: spine.SpinePlugin, mapping: 'spine', sceneKey: 'spine' },
			{
				key: 'rexUI',
				plugin: UIPlugin,
				mapping: 'rexUI',
			},
		],
		global: [
			{
				key: 'rexWebFontLoader',
				plugin: WebFontLoaderPlugin,
				start: true,
			},
			{
				key: 'rexEaseMove',
				plugin: EaseMovePlugin,
				start: true,
			},

			// ...
		],
	},
};

const game = new Phaser.Game(config);
