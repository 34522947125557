import { 
	Cell,
	Slice, 
	Address, 
	Builder, 
	beginCell, 
	ComputeError, 
	TupleItem, 
	TupleReader, 
	Dictionary, 
	contractAddress, 
	ContractProvider, 
	Sender, 
	Contract, 
	ContractABI, 
	ABIType,
	ABIGetter,
	ABIReceiver,
	TupleBuilder,
	DictionaryValue
} from '@ton/core';

export type StateInit = {
	$$type: 'StateInit';
	code: Cell;
	data: Cell;
}

export function storeStateInit(src: StateInit) {
	return (builder: Builder) => {
		let b_0 = builder;
		b_0.storeRef(src.code);
		b_0.storeRef(src.data);
	};
}

export function loadStateInit(slice: Slice) {
	let sc_0 = slice;
	let _code = sc_0.loadRef();
	let _data = sc_0.loadRef();
	return { $$type: 'StateInit' as const, code: _code, data: _data };
}

function loadTupleStateInit(source: TupleReader) {
	let _code = source.readCell();
	let _data = source.readCell();
	return { $$type: 'StateInit' as const, code: _code, data: _data };
}

function storeTupleStateInit(source: StateInit) {
	let builder = new TupleBuilder();
	builder.writeCell(source.code);
	builder.writeCell(source.data);
	return builder.build();
}

function dictValueParserStateInit(): DictionaryValue<StateInit> {
	return {
		serialize: (src, builder) => {
			builder.storeRef(beginCell().store(storeStateInit(src)).endCell());
		},
		parse: (src) => {
			return loadStateInit(src.loadRef().beginParse());
		}
	}
}

export type Context = {
	$$type: 'Context';
	bounced: boolean;
	sender: Address;
	value: bigint;
	raw: Cell;
}

export function storeContext(src: Context) {
	return (builder: Builder) => {
		let b_0 = builder;
		b_0.storeBit(src.bounced);
		b_0.storeAddress(src.sender);
		b_0.storeInt(src.value, 257);
		b_0.storeRef(src.raw);
	};
}

export function loadContext(slice: Slice) {
	let sc_0 = slice;
	let _bounced = sc_0.loadBit();
	let _sender = sc_0.loadAddress();
	let _value = sc_0.loadIntBig(257);
	let _raw = sc_0.loadRef();
	return { $$type: 'Context' as const, bounced: _bounced, sender: _sender, value: _value, raw: _raw };
}

function loadTupleContext(source: TupleReader) {
	let _bounced = source.readBoolean();
	let _sender = source.readAddress();
	let _value = source.readBigNumber();
	let _raw = source.readCell();
	return { $$type: 'Context' as const, bounced: _bounced, sender: _sender, value: _value, raw: _raw };
}

function storeTupleContext(source: Context) {
	let builder = new TupleBuilder();
	builder.writeBoolean(source.bounced);
	builder.writeAddress(source.sender);
	builder.writeNumber(source.value);
	builder.writeSlice(source.raw);
	return builder.build();
}

function dictValueParserContext(): DictionaryValue<Context> {
	return {
		serialize: (src, builder) => {
			builder.storeRef(beginCell().store(storeContext(src)).endCell());
		},
		parse: (src) => {
			return loadContext(src.loadRef().beginParse());
		}
	}
}

export type SendParameters = {
	$$type: 'SendParameters';
	bounce: boolean;
	to: Address;
	value: bigint;
	mode: bigint;
	body: Cell | null;
	code: Cell | null;
	data: Cell | null;
}

export function storeSendParameters(src: SendParameters) {
	return (builder: Builder) => {
		let b_0 = builder;
		b_0.storeBit(src.bounce);
		b_0.storeAddress(src.to);
		b_0.storeInt(src.value, 257);
		b_0.storeInt(src.mode, 257);
		if (src.body !== null && src.body !== undefined) { b_0.storeBit(true).storeRef(src.body); } else { b_0.storeBit(false); }
		if (src.code !== null && src.code !== undefined) { b_0.storeBit(true).storeRef(src.code); } else { b_0.storeBit(false); }
		if (src.data !== null && src.data !== undefined) { b_0.storeBit(true).storeRef(src.data); } else { b_0.storeBit(false); }
	};
}

export function loadSendParameters(slice: Slice) {
	let sc_0 = slice;
	let _bounce = sc_0.loadBit();
	let _to = sc_0.loadAddress();
	let _value = sc_0.loadIntBig(257);
	let _mode = sc_0.loadIntBig(257);
	let _body = sc_0.loadBit() ? sc_0.loadRef() : null;
	let _code = sc_0.loadBit() ? sc_0.loadRef() : null;
	let _data = sc_0.loadBit() ? sc_0.loadRef() : null;
	return { $$type: 'SendParameters' as const, bounce: _bounce, to: _to, value: _value, mode: _mode, body: _body, code: _code, data: _data };
}

function loadTupleSendParameters(source: TupleReader) {
	let _bounce = source.readBoolean();
	let _to = source.readAddress();
	let _value = source.readBigNumber();
	let _mode = source.readBigNumber();
	let _body = source.readCellOpt();
	let _code = source.readCellOpt();
	let _data = source.readCellOpt();
	return { $$type: 'SendParameters' as const, bounce: _bounce, to: _to, value: _value, mode: _mode, body: _body, code: _code, data: _data };
}

function storeTupleSendParameters(source: SendParameters) {
	let builder = new TupleBuilder();
	builder.writeBoolean(source.bounce);
	builder.writeAddress(source.to);
	builder.writeNumber(source.value);
	builder.writeNumber(source.mode);
	builder.writeCell(source.body);
	builder.writeCell(source.code);
	builder.writeCell(source.data);
	return builder.build();
}

function dictValueParserSendParameters(): DictionaryValue<SendParameters> {
	return {
		serialize: (src, builder) => {
			builder.storeRef(beginCell().store(storeSendParameters(src)).endCell());
		},
		parse: (src) => {
			return loadSendParameters(src.loadRef().beginParse());
		}
	}
}

export type Deploy = {
	$$type: 'Deploy';
	queryId: bigint;
}

export function storeDeploy(src: Deploy) {
	return (builder: Builder) => {
		let b_0 = builder;
		b_0.storeUint(2490013878, 32);
		b_0.storeUint(src.queryId, 64);
	};
}

export function loadDeploy(slice: Slice) {
	let sc_0 = slice;
	if (sc_0.loadUint(32) !== 2490013878) { throw Error('Invalid prefix'); }
	let _queryId = sc_0.loadUintBig(64);
	return { $$type: 'Deploy' as const, queryId: _queryId };
}

function loadTupleDeploy(source: TupleReader) {
	let _queryId = source.readBigNumber();
	return { $$type: 'Deploy' as const, queryId: _queryId };
}

function storeTupleDeploy(source: Deploy) {
	let builder = new TupleBuilder();
	builder.writeNumber(source.queryId);
	return builder.build();
}

function dictValueParserDeploy(): DictionaryValue<Deploy> {
	return {
		serialize: (src, builder) => {
			builder.storeRef(beginCell().store(storeDeploy(src)).endCell());
		},
		parse: (src) => {
			return loadDeploy(src.loadRef().beginParse());
		}
	}
}

export type DeployOk = {
	$$type: 'DeployOk';
	queryId: bigint;
}

export function storeDeployOk(src: DeployOk) {
	return (builder: Builder) => {
		let b_0 = builder;
		b_0.storeUint(2952335191, 32);
		b_0.storeUint(src.queryId, 64);
	};
}

export function loadDeployOk(slice: Slice) {
	let sc_0 = slice;
	if (sc_0.loadUint(32) !== 2952335191) { throw Error('Invalid prefix'); }
	let _queryId = sc_0.loadUintBig(64);
	return { $$type: 'DeployOk' as const, queryId: _queryId };
}

function loadTupleDeployOk(source: TupleReader) {
	let _queryId = source.readBigNumber();
	return { $$type: 'DeployOk' as const, queryId: _queryId };
}

function storeTupleDeployOk(source: DeployOk) {
	let builder = new TupleBuilder();
	builder.writeNumber(source.queryId);
	return builder.build();
}

function dictValueParserDeployOk(): DictionaryValue<DeployOk> {
	return {
		serialize: (src, builder) => {
			builder.storeRef(beginCell().store(storeDeployOk(src)).endCell());
		},
		parse: (src) => {
			return loadDeployOk(src.loadRef().beginParse());
		}
	}
}

export type FactoryDeploy = {
	$$type: 'FactoryDeploy';
	queryId: bigint;
	cashback: Address;
}

export function storeFactoryDeploy(src: FactoryDeploy) {
	return (builder: Builder) => {
		let b_0 = builder;
		b_0.storeUint(1829761339, 32);
		b_0.storeUint(src.queryId, 64);
		b_0.storeAddress(src.cashback);
	};
}

export function loadFactoryDeploy(slice: Slice) {
	let sc_0 = slice;
	if (sc_0.loadUint(32) !== 1829761339) { throw Error('Invalid prefix'); }
	let _queryId = sc_0.loadUintBig(64);
	let _cashback = sc_0.loadAddress();
	return { $$type: 'FactoryDeploy' as const, queryId: _queryId, cashback: _cashback };
}

function loadTupleFactoryDeploy(source: TupleReader) {
	let _queryId = source.readBigNumber();
	let _cashback = source.readAddress();
	return { $$type: 'FactoryDeploy' as const, queryId: _queryId, cashback: _cashback };
}

function storeTupleFactoryDeploy(source: FactoryDeploy) {
	let builder = new TupleBuilder();
	builder.writeNumber(source.queryId);
	builder.writeAddress(source.cashback);
	return builder.build();
}

function dictValueParserFactoryDeploy(): DictionaryValue<FactoryDeploy> {
	return {
		serialize: (src, builder) => {
			builder.storeRef(beginCell().store(storeFactoryDeploy(src)).endCell());
		},
		parse: (src) => {
			return loadFactoryDeploy(src.loadRef().beginParse());
		}
	}
}

export type Checkin = {
	$$type: 'Checkin';
	amount: bigint;
}

export function storeCheckin(src: Checkin) {
	return (builder: Builder) => {
		let b_0 = builder;
		b_0.storeUint(3379687794, 32);
		b_0.storeCoins(src.amount);
	};
}

export function loadCheckin(slice: Slice) {
	let sc_0 = slice;
	if (sc_0.loadUint(32) !== 3379687794) { throw Error('Invalid prefix'); }
	let _amount = sc_0.loadCoins();
	return { $$type: 'Checkin' as const, amount: _amount };
}

function loadTupleCheckin(source: TupleReader) {
	let _amount = source.readBigNumber();
	return { $$type: 'Checkin' as const, amount: _amount };
}

function storeTupleCheckin(source: Checkin) {
	let builder = new TupleBuilder();
	builder.writeNumber(source.amount);
	return builder.build();
}

function dictValueParserCheckin(): DictionaryValue<Checkin> {
	return {
		serialize: (src, builder) => {
			builder.storeRef(beginCell().store(storeCheckin(src)).endCell());
		},
		parse: (src) => {
			return loadCheckin(src.loadRef().beginParse());
		}
	}
}

 type DogMutant_init_args = {
	$$type: 'DogMutant_init_args';
}

function initDogMutant_init_args(src: DogMutant_init_args) {
	return (builder: Builder) => {
		let b_0 = builder;
	};
}

async function DogMutant_init() {
	const __code = Cell.fromBase64('te6ccgECDwEAAtMAART/APSkE/S88sgLAQIBYgIDAs7QAdDTAwFxsKMB+kABINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiFRQUwNvBPhhAvhi2zxZ2zzy4ILI+EMBzH8BygABINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiM8Wye1UBAUCAVgLDAF27UTQ1AH4Y9IAAY4g+kABINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiDHgMPgo1wsKgwm68uCJ2zwGAr4BkjB/4HAh10nCH5UwINcLH94gghDJcfFyuo63MNMfAYIQyXHxcrry4IH6AAExMIIAh0X4QW8kE18Dggh6EgC+8vT4Qn+CCEbNAHIQI21tbds8f+CCEJRqmLa64wIwcAkHAAT4QgFO0x8BghCUapi2uvLggdM/ATHIAYIQr/kPV1jLH8s/yfhCAXBt2zx/CAE6bW0ibrOZWyBu8tCAbyIBkTLiECRwAwSAQlAj2zwJAcrIcQHKAVAHAcoAcAHKAlAFINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiM8WUAP6AnABymgjbrORf5MkbrPilzMzAXABygDjDSFus5x/AcoAASBu8tCAAcyVMXABygDiyQH7AAoAmH8BygDIcAHKAHABygAkbrOdfwHKAAQgbvLQgFAEzJY0A3ABygDiJG6znX8BygAEIG7y0IBQBMyWNANwAcoA4nABygACfwHKAALJWMwAubu9GCcFzsPV0srnsehOw51kqFG2aCcJ3WNS0rZHyzItOvLf3xYjmCcCBVwBuAZ2OUzlg6rkclssOCcBvUne+VRZbxx1PT3gVZwyaCcJ2XTlqzTstzOg6WbZRm6KSAIBSA0OABGwr7tRNDSAAGAAdbJu40NWlwZnM6Ly9RbVhMN05aTmlHZlIyZmFtV1NSdlA5eG85V2ZFSGVOd0FzeDVTb1l5ZkZQdlJhgg');
	const __system = Cell.fromBase64('te6cckECEQEAAt0AAQHAAQEFoCrzAgEU/wD0pBP0vPLICwMCAWIEDALO0AHQ0wMBcbCjAfpAASDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IhUUFMDbwT4YQL4Yts8Wds88uCCyPhDAcx/AcoAASDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IjPFsntVAUHAXbtRNDUAfhj0gABjiD6QAEg10mBAQu68uCIINcLCiCBBP+68tCJgwm68uCIMeAw+CjXCwqDCbry4InbPAYABPhCAr4BkjB/4HAh10nCH5UwINcLH94gghDJcfFyuo63MNMfAYIQyXHxcrry4IH6AAExMIIAh0X4QW8kE18Dggh6EgC+8vT4Qn+CCEbNAHIQI21tbds8f+CCEJRqmLa64wIwcAoIAU7THwGCEJRqmLa68uCB0z8BMcgBghCv+Q9XWMsfyz/J+EIBcG3bPH8JATptbSJus5lbIG7y0IBvIgGRMuIQJHADBIBCUCPbPAoByshxAcoBUAcBygBwAcoCUAUg10mBAQu68uCIINcLCiCBBP+68tCJgwm68uCIzxZQA/oCcAHKaCNus5F/kyRus+KXMzMBcAHKAOMNIW6znH8BygABIG7y0IABzJUxcAHKAOLJAfsACwCYfwHKAMhwAcoAcAHKACRus51/AcoABCBu8tCAUATMljQDcAHKAOIkbrOdfwHKAAQgbvLQgFAEzJY0A3ABygDicAHKAAJ/AcoAAslYzAIBWA0OALm7vRgnBc7D1dLK57HoTsOdZKhRtmgnCd1jUtK2R8syLTry398WI5gnAgVcAbgGdjlM5YOq5HJbLDgnAb1J3vlUWW8cdT094FWcMmgnCdl05as07LczoOlm2UZuikgCAUgPEAARsK+7UTQ0gABgAHWybuNDVpcGZzOi8vUW1YTDdOWk5pR2ZSMmZhbVdTUnZQOXhvOVdmRUhlTndBc3g1U29ZeWZGUHZSYYIJq5llY=');
	let builder = beginCell();
	builder.storeRef(__system);
	builder.storeUint(0, 1);
	initDogMutant_init_args({ $$type: 'DogMutant_init_args' })(builder);
	const __data = builder.endCell();
	return { code: __code, data: __data };
}

const DogMutant_errors: { [key: number]: { message: string } } = {
	2: { message: `Stack underflow` },
	3: { message: `Stack overflow` },
	4: { message: `Integer overflow` },
	5: { message: `Integer out of expected range` },
	6: { message: `Invalid opcode` },
	7: { message: `Type check error` },
	8: { message: `Cell overflow` },
	9: { message: `Cell underflow` },
	10: { message: `Dictionary error` },
	13: { message: `Out of gas error` },
	32: { message: `Method ID not found` },
	34: { message: `Action is invalid or not supported` },
	37: { message: `Not enough TON` },
	38: { message: `Not enough extra-currencies` },
	128: { message: `Null reference exception` },
	129: { message: `Invalid serialization prefix` },
	130: { message: `Invalid incoming message` },
	131: { message: `Constraints error` },
	132: { message: `Access denied` },
	133: { message: `Contract stopped` },
	134: { message: `Invalid argument` },
	135: { message: `Code of a contract was not found` },
	136: { message: `Invalid address` },
	137: { message: `Masterchain support is not enabled for this contract` },
	34629: { message: `You need send more TON` },
}

const DogMutant_types: ABIType[] = [
	{"name":"StateInit","header":null,"fields":[{"name":"code","type":{"kind":"simple","type":"cell","optional":false}},{"name":"data","type":{"kind":"simple","type":"cell","optional":false}}]},
	{"name":"Context","header":null,"fields":[{"name":"bounced","type":{"kind":"simple","type":"bool","optional":false}},{"name":"sender","type":{"kind":"simple","type":"address","optional":false}},{"name":"value","type":{"kind":"simple","type":"int","optional":false,"format":257}},{"name":"raw","type":{"kind":"simple","type":"slice","optional":false}}]},
	{"name":"SendParameters","header":null,"fields":[{"name":"bounce","type":{"kind":"simple","type":"bool","optional":false}},{"name":"to","type":{"kind":"simple","type":"address","optional":false}},{"name":"value","type":{"kind":"simple","type":"int","optional":false,"format":257}},{"name":"mode","type":{"kind":"simple","type":"int","optional":false,"format":257}},{"name":"body","type":{"kind":"simple","type":"cell","optional":true}},{"name":"code","type":{"kind":"simple","type":"cell","optional":true}},{"name":"data","type":{"kind":"simple","type":"cell","optional":true}}]},
	{"name":"Deploy","header":2490013878,"fields":[{"name":"queryId","type":{"kind":"simple","type":"uint","optional":false,"format":64}}]},
	{"name":"DeployOk","header":2952335191,"fields":[{"name":"queryId","type":{"kind":"simple","type":"uint","optional":false,"format":64}}]},
	{"name":"FactoryDeploy","header":1829761339,"fields":[{"name":"queryId","type":{"kind":"simple","type":"uint","optional":false,"format":64}},{"name":"cashback","type":{"kind":"simple","type":"address","optional":false}}]},
	{"name":"Checkin","header":3379687794,"fields":[{"name":"amount","type":{"kind":"simple","type":"uint","optional":false,"format":"coins"}}]},
]

const DogMutant_getters: ABIGetter[] = [
]

const DogMutant_receivers: ABIReceiver[] = [
	{"receiver":"internal","message":{"kind":"typed","type":"Checkin"}},
	{"receiver":"internal","message":{"kind":"typed","type":"Deploy"}},
]

export class DogMutant implements Contract {
	
	static async init() {
		return await DogMutant_init();
	}
	
	static async fromInit() {
		const init = await DogMutant_init();
		const address = contractAddress(0, init);
		return new DogMutant(address, init);
	}
	
	static fromAddress(address: Address) {
		return new DogMutant(address);
	}
	
	readonly address: Address; 
	readonly init?: { code: Cell, data: Cell };
	readonly abi: ContractABI = {
		types:  DogMutant_types,
		getters: DogMutant_getters,
		receivers: DogMutant_receivers,
		errors: DogMutant_errors,
	};
	
	private constructor(address: Address, init?: { code: Cell, data: Cell }) {
		this.address = address;
		this.init = init;
	}
	
	async send(provider: ContractProvider, via: Sender, args: { value: bigint, bounce?: boolean| null | undefined }, message: Checkin | Deploy) {
		
		let body: Cell | null = null;
		if (message && typeof message === 'object' && !(message instanceof Slice) && message.$$type === 'Checkin') {
			body = beginCell().store(storeCheckin(message)).endCell();
		}
		if (message && typeof message === 'object' && !(message instanceof Slice) && message.$$type === 'Deploy') {
			body = beginCell().store(storeDeploy(message)).endCell();
		}
		if (body === null) { throw new Error('Invalid message type'); }
		
		await provider.internal(via, { ...args, body: body });
		
	}
	
}